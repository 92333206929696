$white: #FFFFFF;
$black: #000000;
$gray: #d4d4d4;
$overlay-color: #2c2d33;
$green: #72cc96;

$red: #ee5f5b;
$blue: #19B5FE;
$yellow: #f89406;
$aqua: #7FDBFF;

$base-font: "weblysleek_uilight", sans-serif;
$code-font: "LiberationMonoRegular",monospace;
// break point
$small: 750px;