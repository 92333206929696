@font-face {
  font-family: 'weblysleek_uilight';
  src: url('../fonts/weblysleekuil.eot');
  src: url('../fonts/weblysleekuil.eot?#iefix') format('embedded-opentype'), url('../fonts/weblysleekuil.woff2') format('woff2'), url('../fonts/weblysleekuil.woff') format('woff'), url('../fonts/weblysleekuil.ttf') format('truetype'), url('../fonts/weblysleekuil.svg#weblysleek_uilight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LiberationMonoRegular';
  src: url('../fonts/liberation-mono-webfont.eot');
  src: url('../fonts/liberation-mono-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/liberation-mono-webfont.woff') format('woff'), url('../fonts/liberation-mono-webfont.ttf') format('truetype'), url('../fonts/liberation-mono-webfont.svg#LiberationMonoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
